import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const StopDebtCollectorCallsPage = () => {
    const article = {
        id: '79fb735b-55bc-5435-8f6f-f5fdea63d919',
        title: 'How To Permanently Stop Debt Collectors From Calling You',
        slug: '/stop-debt-collector-calls/',
        date: '2018-01-28T18:29:56.000Z',
        modified: '2021-10-29T16:51:42.000Z',
        excerpt: 'Millions of Americans are delinquent on debt payments. Are you getting harassed by debt collectors? These 9 strategies can help you stop the calls for good.',
        featured_image: {
            source_url: '/media/stop-debt-collector-calls.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 10,
        content: (
            <>
                <p>Have you received an unwanted call from a collection agency during dinner?</p>
                <p>You aren&rsquo;t alone: one in three Americans holds debt in collections, according to the Urban Institute.</p>
                <p>The amount owed? A whopping $1,450.</p>
                <p>Getting collection calls at work, at dinner, or after hours repeatedly can be nerve-racking and even agonizing.</p>
                <p>If that&rsquo;s the situation you&rsquo;re in, you need to know that plenty of others have been where you are now and have since broken free.</p>
                <p>
                    Don&rsquo;t panic and change your phone number. You
                    {' '}
                    <em>can</em>
                    {' '}
                    get through this.
                </p>
                <p>And with the right approach, you can stop the phone calls for good!</p>
                <p>Debtors have rights too, and there are laws governing how far creditors can go to collect.</p>
                <h3>Don&rsquo;t presume harassment is OK or that you deserve it because you have outstanding debt or a low credit score</h3>
                <p>
                    Maybe you didn&rsquo;t understand the
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=stop-debt-collector-calls&amp;sub2=loan" rel="noopener noreferrer" target="_blank">loan</a>
                    {' '}
                    terms and options when you signed up. Or maybe you&rsquo;re unaware of your basic rights.
                </p>
                <p>Either way, some homework to learn your options can mean a world of difference.</p>
                <p>We created DebtConsolidation.com to arm you with knowledge and provide you with relief, no matter how much money you owe or how low your credit score may be.</p>
                <p>
                    So again, remember: you aren&rsquo;t alone and you
                    {' '}
                    <em>can</em>
                    {' '}
                    end the sleepless nights. Here&rsquo;s how to permanently stop your creditors from calling you.
                </p>
                <p><LazyLoadImage src="/media/how-consumers-are-contacted-by-debt-collectors-2017-1.png" alt="How Consumers Are Contacted By Debt Collectors (2017)" /></p>
                <h2 id="learn-your-rights">Learn Your Rights</h2>
                <ul className="list">
                    {' '}
                    <li>Borrowers are granted rights under the Fair Debt Collection Practices Act (FDCPA).</li>
                    {' '}
                    <li>
                        Collection agencies
                        {' '}
                        <em>can&rsquo;t</em>
                        {' '}
                        threaten to sue you as an intimidation tactic.
                    </li>
                    {' '}
                    <li>Nor can they take money from your Social Security income if you&rsquo;re retired.</li>
                </ul>
                <h3>Don&rsquo;t stand for scare tactics</h3>
                <p>Every creditor is different and some may be more aggressive than others.</p>
                <p>Every collection agency is different since they&rsquo;ve usually bought delinquent debt at a discount in hopes of recovering the full amount. The more they scare you into paying, the more they earn.</p>
                <p>Keep that in mind and don&rsquo;t be afraid to assert your rights if you need to.</p>
                <p>After all, a scammer may have picked your phone number randomly in hopes of scaring you into paying money you don&rsquo;t owe.</p>
                <p>
                    <strong>Avoid scammers by asking questions.</strong>
                    {' '}
                    Legitimate callers should be able to answer these questions without hesitation or excuses:
                </p>
                <ul className="list">
                    {' '}
                    <li>What is your full name?</li>
                    {' '}
                    <li>Which company do you represent?</li>
                    {' '}
                    <li>What is a direct phone number where I can reach you?</li>
                    {' '}
                    <li>What is your address?</li>
                    {' '}
                    <li>May I call you right back?</li>
                </ul>
                <p>Take notice of any resistance or pressure. A legitimate agent would be comfortable with you hanging up and calling back.</p>
                <h2 id="make-the-first-move">Make the First Move</h2>
                <h3>Be first to call when your account becomes delinquent to show you&rsquo;re on top of your debt</h3>
                <p>Seem strange?</p>
                <p>
                    Maybe, but you
                    {' '}
                    <em>will</em>
                    {' '}
                    get a call anyway.
                </p>
                <p>
                    <strong>Being first to call shows the collector two things. </strong>
                    First, that you know there&rsquo;s a problem, and second, that you&rsquo;re willing to solve it.
                </p>
                <p>And if you were a good customer before running into trouble, it&rsquo;s more likely you&rsquo;ll get a favorable response from your creditor and a better deal for repaying what you owe.</p>
                <p>
                    You may also be able to move your debt,
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/">consolidating what you owe with new lenders</a>
                    {' '}
                    that will work with you.
                </p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="step-bill-collectors" />
                <h3>Have your statement ready and a payment plan in mind</h3>
                <p>But you also need to be prepared for the worst.</p>
                <p>Be truthful about why you&rsquo;re late, and have your statement ready so you can talk intelligently about what&rsquo;s due.</p>
                <p>
                    <strong>Revisit your budget and be prepared with a repayment proposal.</strong>
                    {' '}
                    Be realistic and include a date for when you&rsquo;ll starting paying back what you owe.
                </p>
                <p>
                    <strong>Take action now to get your creditor off your back.</strong>
                    {' '}
                    Creditors won&rsquo;t need to pester you if you&rsquo;ve stayed in contact and are making good on a plan to pay back what you owe.
                </p>
                <h2 id="check-what-you-owe">Check What You Owe</h2>
                <p>We know phone calls from creditors and collection agencies can be scary. It feels like you&rsquo;re in serious trouble, right?</p>
                <p>
                    <strong>Don&rsquo;t take collection agencies at their word.</strong>
                    {' '}
                    They may try to collect an old debt. And it may be one you&rsquo;ve long since forgotten about. They&rsquo;re hoping you&rsquo;ll pay with no questions asked.
                </p>
                <h3>But know that debt can expire if it isn&rsquo;t collected fast enough</h3>
                <p>
                    Each state has different rules called &quot;
                    <a href="https://www.consumerfinance.gov/consumer-tools/debt-collection/key-terms/">statute of limitations</a>
                    &quot; for how long debts can hang over you.
                </p>
                <p><LazyLoadImage src="/media/statute-of-limitations-for-credit-card-debt-2017-2.png" alt="Statute of Limitations For Credit Card Debt (2017)" /></p>
                <p>
                    <strong>Outstanding debt for three to six years may expire.</strong>
                    {' '}
                    That is, from the last payment. So, no matter what a collection agency tells you, the clock on your overdue debt
                    {' '}
                    <em>does not restart</em>
                    {' '}
                    if purchased by a collections agency.
                </p>
                <p>
                    <strong>Get a copy of your </strong>
                    {' '}
                    <a href="https://www.annualcreditreport.com/index.action">free annual credit report</a>
                    {' '}
                    <strong> from each credit bureau FIRST.</strong>
                    {' '}
                    Don&rsquo;t agree to anything before you take a magnifying glass to your reports. And keep every bit of correspondence about debts you&rsquo;ve been unable to pay.
                </p>
                <p>If a collections agency starts calling to collect on a debt that&rsquo;s outside the statute of limitations, you&rsquo;ll have grounds to demand they stop.</p>
                <p>You&rsquo;ll have to make your request in writing.</p>
                <h2 id="dispute-potential-inaccuracies">Dispute Potential Inaccuracies</h2>
                <p>
                    Collectors get it wrong — willfully or not —
                    {' '}
                    <em>a lot</em>
                    {' '}
                    more often than you might think.
                </p>
                <p>As of Dec. 2016, data from the Consumer Financial Protection Bureau (CFPB) found that 39% of consumers complaining about debt collection practices said they were being contacted about debts they no longer owed.</p>
                <p>Nor is it difficult to find stories of consumers who&rsquo;ve been either overcharged or misled about how much they owe.</p>
                <p>Which, again, is why you should get your credit report from each credit bureau before agreeing to pay anything to a collections agency.</p>
                <h3>Your rights never expire</h3>
                <p>The important thing to remember when dealing with collection agencies is that vigilance on your end pays off.</p>
                <p>
                    <strong>Your rights don&rsquo;t expire when your account becomes delinquent.</strong>
                    {' '}
                    You have the right to demand the agency attempting to collect from you verify what you owe in writing within five days of contacting you.
                </p>
                <p>Compare what they send you to what&rsquo;s on your credit reports.</p>
                <p>
                    <strong>Dispute the claim within 30 days of initial contact</strong>
                    . Then, you&rsquo;ll force the agency to verify again, also in writing, what it is you owe. And they&rsquo;ll be prohibited from calling in the meantime.
                </p>
                <p>
                    <strong>Request clarification and updates as often as necessary.</strong>
                    {' '}
                    Unlike creditors, collection agencies aren&rsquo;t required to send you statements, making it easy for them to hide interest and fees while demanding you keep paying.
                </p>
                <h2 id="negotiate-a-settlement">Negotiate a Settlement</h2>
                <p>
                    While it&rsquo;s always better to
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-settlement/">settle your debts</a>
                    {' '}
                    with your original creditor, rather than allow a collections agency to step in, it still may not be as bad as you think.
                </p>
                <p><LazyLoadImage src="/media/u-s-families-with-debt-3.png" alt="U.S. Families With Debt" /></p>
                <p>Why? Remember the business model: because they&rsquo;ve bought your debt at a discount, collection agencies have room to negotiate a settlement with you and still profit.</p>
                <h3>Put it in writing, and make sure it&rsquo;s accurate</h3>
                <p>Most often, you&rsquo;ll have to negotiate over the phone. That&rsquo;s fine.</p>
                <p>
                    <strong>Just be sure to take notes and keep everything in one place.</strong>
                    {' '}
                    For example, note the date and time of every call, the name of the person you talked to, what, if any, legal action was discussed, and so on.
                </p>
                <p>The more specifics you have, the better.</p>
                <p>Once you&rsquo;ve reached a verbal settlement make sure you get it verified in writing.</p>
                <p>
                    <strong>Don&rsquo;t pay anything until you have a </strong>
                    <strong>settlement </strong>
                    <strong>letter. </strong>
                    Why? A debt collector could claim your payment as evidence you agreed to the terms set by your original creditor — and the settlement you worked so hard to get goes up in smoke.
                </p>
                <p>
                    <strong>Insist on seeing paperwork before making a payment. </strong>
                    Especially if the representative you&rsquo;re on the phone with is demanding you pay immediately.
                </p>
                <p>Don&rsquo;t budge.</p>
                <p>Instead, politely say: &quot;As soon as I have the agreement in writing, I&rsquo;ll call to verify I have it and get the first payment in the mail to you.&quot;</p>
                <p>Say it over and over and over again if you must.</p>
                <h2 id="write-to-harassing-debt-collectors">Write to Harassing Debt Collectors</h2>
                <p>What if you don&rsquo;t have the money to settle?</p>
                <p>You still don&rsquo;t have to accept endless phone calls at all hours.</p>
                <p>You have rights as a borrower, and that includes the right to tell the collections agency bothering you to back off — so long as you do it in writing.</p>
                <p>
                    <a href="https://www.consumerfinance.gov/ask-cfpb/what-should-i-do-when-a-debt-collector-contacts-me-en-1695/">The CFPB has a useful list of form letters</a>
                    {' '}
                    you can copy for making the request.
                </p>
                <h3>Keep a dated copy of the letter for your records</h3>
                <p>You&rsquo;ll need to do some homework first.</p>
                <p>
                    <strong>The next time a rep calls, get their name and office address.</strong>
                    {' '}
                    They&rsquo;re required to give you this, by the way. You may also have it if the original verification letter included a return address.
                </p>
                <p>Include all that information and the request they stop calling in your note and make a copy for your records.</p>
                <p>Or just follow the CFPB form letter that best fits your situation.</p>
                <p>
                    <strong>Send an original letter via certified mail as evidence.</strong>
                    {' '}
                    Pay the extra fee for a return receipt so you have proof of when the collections agency received your letter.
                </p>
                <p>You shouldn&rsquo;t hear anything further.</p>
                <p>And if you do, it&rsquo;ll be for one of only two legally-permitted reasons:</p>
                <ul className="list">
                    {' '}
                    <li>to let you know no further action will be taken</li>
                    {' '}
                    <li>to let you know they&rsquo;ll be taking action, such as filing a lawsuit</li>
                </ul>
                <h2 id="contact-your-states-attorney-general">Contact Your State&rsquo;s Attorney General</h2>
                <p>Most attorneys generals have staff and resources dedicated to investigating debt collection complaints.</p>
                <p><LazyLoadImage src="/media/types-of-debt-collection-complaints-reported-by-consumers-4.png" alt="Types Of Debt Collection Complaints Reported By Consumers" /></p>
                <p>
                    <strong>Debt collection scams are a known problem through the U.S.</strong>
                    {' '}
                    Every state takes action differently, but some, like Colorado, allow you to
                    {' '}
                    <a href="https://coag.gov/car/licensing">download a list of agencies</a>
                    {' '}
                    licensed to collect debts with notes about when actions were taken against them by the AG.
                </p>
                <p>
                    <strong>Let the debt collector know you&rsquo;re an informed consumer.</strong>
                    {' '}
                    Imagine getting a call from a particularly bullying debt collector who has been disciplined previously.
                </p>
                <p>
                    Asking when they &quot;settled action taken against them by the Attorney General&quot; does two things: first, it lets the collector know you&rsquo;ve done some homework, and second, if you
                    {' '}
                    <em>are</em>
                    {' '}
                    dealing with a scammer, you&rsquo;ve let them know you&rsquo;re aware of how to report them.
                </p>
                <p>Either way, you&rsquo;ve made it known to the collector that you won&rsquo;t be intimidated into paying, which may get them to drop pursuit.</p>
                <h3>Most states allow for reporting harassment online</h3>
                <p>And if they don&rsquo;t? Filling out a simple online form will register your complaint with the attorney general, whose office may follow-up directly if your story is particularly egregious.</p>
                <p>More likely, the AG will keep tabs on agencies that are repeat offenders and dole out fines or restrictions.</p>
                <h2 id="get-an-attorney">Get An Attorney</h2>
                <p>Debt collectors can be relentless or even dirty.</p>
                <p>
                    Say you&rsquo;re 120 days past due on a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=stop-debt-collector-calls&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    and the rep is calling you at 5 am to ask for payment. Or the rep is calling you at work when your office has a policy of not accepting calls from collections agencies.
                </p>
                <p>
                    <strong>Both tactics are illegal, in which case it may be time to hire an </strong>
                    <strong>attorney</strong>
                    <strong>.</strong>
                    {' '}
                    Avvo has an online tool to help you
                    {' '}
                    <a href="https://www.avvo.com/debt-collection-lawyer.html">find a local attorney who has experience with debt collection cases</a>
                    .
                </p>
                <h3>Collection agencies must deal with your lawyer</h3>
                <p>Sound crazy? Not if you value peace. Because once you&rsquo;ve introduced the collector to your lawyer, they&rsquo;re no longer allowed to contact you.</p>
                <p>Better still: attorneys that specialize in defending consumers against debt collectors will often provide services pro bono, collecting their fees from miscreant agencies that have probably broken the law.</p>
                <p>Keep good, detailed records of every interaction if you think you&rsquo;ll need an attorney at some point.</p>
                <h2 id="sue-for-damages">Sue for Damages</h2>
                <p>In extreme cases, it may be worth filing a lawsuit, especially if you can document multiple instances where an attorney believes your collector violated the law.</p>

                <h3>Consult your attorney, and file within a year of the violation</h3>
                <p>The key here is having an attorney who sees a case to be made.</p>
                <p>Why? Because collection agencies sue consumers all the time and will have years of experience skirting boundaries. An expert attorney can tell you whether your records prove misconduct.</p>
                <p> </p>
                <p><LazyLoadImage src="/media/revenue-of-u-s-lawyers-offices-5.png" alt="Revenue of U.S. Lawyer&rsquo;s Offices" /></p>
                <p>
                    <strong>A judge can require the collector to pay for damages.</strong>
                    {' '}
                    Lost wages and medical bills suffered because of illegal collection practices, for example.
                </p>
                <p>And even if you can&rsquo;t prove damages, you may still be awarded up to $1,000 while your attorney collects fees and court costs.</p>
                <p>
                    <strong>Be warned: winning a judgment doesn&rsquo;t cancel your debt!</strong>
                    {' '}
                    Though your harasser collector may be far more willing to settle in light of the judgment then they may have been before.
                </p>
                <h3>Be proactive, and you won&rsquo;t be a victim</h3>
                <p>
                    You
                    {' '}
                    <em>can</em>
                    {' '}
                    get through this and get free of debt and debt collectors.
                </p>
                <p>First, you need to know your rights. Then be proactive, research, ask questions, and assume nothing.</p>
                <p>Put everything in writing and get help if you need it. Harassment is never, ever OK. In fact, it&rsquo;s against the law — and you have every right to sue to make it stop.</p>
                <p>
                    Next step: use the resources here to develop a plan to
                    {' '}
                    <a href="https://www.debtconsolidation.com/how-to-get-out-of-debt/">get out of debt for good</a>
                    . Ready to take action? Pick a tactic and get to work, and then keep going till the calls stop!
                </p>
                <p>Have you ever hired an attorney to sue a debt collector?</p>
                <p>Settled a debt at a fraction of what you owed?</p>
                <p>Any great tips (or nightmares) to share with the rest of us?</p>
                <p>Let us know in the comments below.</p>
            </>
        ),
    };
    return (
        <Post article={article} />
    );
};

export default StopDebtCollectorCallsPage;
